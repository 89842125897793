// Dependency
import React from "react";

// Styles
import * as styles from './loader-overlay.module.scss'

// Template
const MiniLoader = ({active, theme}) => {
    return (
        active ?
            <div className={`${styles.cdMiniLoaderOverlay} ${theme === "dark" ? styles.cdDarkLoader : '' }`}>
                <div className={styles.loader1}><span></span></div>
            </div> : ''
    )
}

export default MiniLoader