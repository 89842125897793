// Dependency
import React, { useEffect } from "react";
import axios from "axios";

// Components
import { useCart } from "../../../contexts/cart-context";
import Storage from "../../../services/localStorage.service";

// Template
export default function ProductList({ isCampaign, createProductList, country }) {

  const { cart, setCart } = useCart();

  const market = country ? country : 'se';

  useEffect(() => {
    if (cart) {
      axios.defaults.headers.post['Accept'] = `*/*;api-token: ${cart.token}`;
      axios({
        method: 'post',
        url: `${process.env.CENTRA_URL}/products`
      }).then(response => {
        createProductList(response.data.products);
      }).catch((error) => {
        console.log(error)
      });
    }
  }, [cart])

  useEffect(() => {
    if (isCampaign && cart.token) {
      axios.defaults.headers['put']['Accept'] = `*/*;api-token: ${cart.token}`
      axios({
        method: 'put',
        url: `${process.env.CENTRA_URL}/campaign-site`,
        data: {
          uri: `member-${market}`
        }
      }).then(response => {
        //Save cart content in localstorage and update state
        Storage.setWithExpiry('selection', response.data, 302400000)
        setCart(response.data);
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [isCampaign, cart.token])

  return (
    <>
    </>
  )
}
