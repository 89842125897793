// Dependency
import React from "react";

// Styles
import * as backgroundCardsStyles from './background-cards.module.scss'

// Components
import BackgroundCard from "./cards/background-card";


// Template
export default function BackgroundCards(props) {

    return (
        <section className={backgroundCardsStyles.cdBackgroundCardSection}>
            <div className='cd-max-width '>
                <div className={backgroundCardsStyles.cdBackgroundCardGrid}>

                    {props.data.map((backgroundCard, index) => {
                        return (
                            <div key={index.toString()} className={backgroundCardsStyles.cdBackgroundCardGridCard}>
                                <BackgroundCard data={backgroundCard} country={props.country} />
                            </div>
                        )
                    })}

                </div>
            </div>
        </section>
    )
}
