// Dependency
import React, { useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

// Components
import Layout from "../../components/layouts";
import Hero from "../../components/hero/hero";
import CenteredContent from "../../components/centered-content";
import ProductCard from "../../components/cards/product-card";
import BackgroundCards from "../../components/background-cards";
import Sticky from "../../components/sticky/sticky";
import StickyText from "../../components/sticky/sticky-text";
import InstaFeed from "../../components/instafeed/instafeed";
import FooterTrust from "../../components/trustpilot/footer-trustpilot";

// Internationalization
import { useTranslation } from 'react-i18next';

// Assets
import pattern from '../../images/pattern.png'

// Hooks
import ProductList from "../product/hooks/product-list";

// Styles
import * as compareStyles from './../pages/compare.module.scss';
import * as shopStyles from "./../../templates/pages/shop.module.scss";
import * as stickyStyles from '../../components/sticky/sticky.module.scss';


// GraphQL to get Dato data
export const query = graphql`
  query  ($slug: String!, $locale: String!) {
    allDatoCmsCollabPage(
      filter: {seoSlug: { eq: $slug },locale: {eq: $locale}}
    ){
    nodes {
        locale
        seoSlug
        title
        heroContent
        desktopHero{
            fluid(maxWidth: 1500){
            ...GatsbyDatoCmsFluid
          }
        }
        mobileHero{
            fluid {
            ...GatsbyDatoCmsFluid
          }
        }
    desktopVideo{
      url
    }
    mobileVideo{
      url
    }
    centeredContent
    products{
      centraId
      slug
      featuredImage{
        fluid {
          ...GatsbyDatoCmsFluid
        }
      }
      newPrice
      locale
      title
      parentProductName
      trustpilotId
      saleBadge
      bestseller
      batteryText
      batteryTime
      batteryType
      performance
      beatsPerMinute
      loadTime
      strokeLength
      motor
      noiseLevel
      maximumPressure
      specifications
      weight
      size
      dimensions
    }
    scrollGrids{
      text
      images{
        fluid{
          ...GatsbyDatoCmsFluid
        }
      }
      textToRight
    }
    backgroundCards{
      featuredMedia{
        fluid(maxWidth: 500){
          ...GatsbyDatoCmsFluid
        }
        url
      }
      video{
        url
        format
      }
      title
      subtitle
      link
      buttonText
    }
    seoMetaTags {
      tags
      id
      }
    }
  }
}
`


// Template
const Collab = (props) => {
  const pageData = props.data.allDatoCmsCollabPage.nodes;

  const mediaData = {
    videoSrcURL: pageData && pageData[0].desktopVideo ? pageData[0].desktopVideo.url : '',
    videoSrcURLMobile: pageData && pageData[0].mobileVideo ? pageData[0].mobileVideo.url : '',
  }
  const sources = [
    pageData[0].mobileHero.fluid,
    {
      ...pageData[0].desktopHero.fluid,
      media: `(min-width: 900px)`,
    },
  ]

  const [productList, setProductList] = useState([]);

  // Translation Function
  const { t } = useTranslation();

  return (

    <Layout seoData={pageData[0].seoMetaTags} country={props.pageContext.country} location={props.location}>
      <ProductList createProductList={setProductList} />
      <Hero
        cname={"cd-hero-small-text"}
        sources={sources}
        mediaData={mediaData}
      >
        { //  is visible only if data exist
          pageData && pageData[0].heroContent && <div
            dangerouslySetInnerHTML={{ __html: pageData[0].heroContent }}>
          </div>
        }

      </Hero>

      <div>

        <CenteredContent>
          { // MediaBanner is visible only if data exist
            pageData && pageData[0].centeredContent && <div
              dangerouslySetInnerHTML={{ __html: pageData[0].centeredContent }}>
            </div>
          }
        </CenteredContent>

        <div className={shopStyles.cdShopWrapper}>
          <div className={` ${shopStyles.cdShopCatGrid} cd-max-width`}>
            {pageData && pageData[0].products && pageData[0].products.map((product, index) => {
              let prodFilter = productList.filter((prod) => {
                return product.centraId.toString() === prod.centraProduct;
              })

              let centraProd = prodFilter.length > 0 ? prodFilter[0] : [];
              return (
                <div key={index} className={shopStyles.cdShopProductCard}>
                  <ProductCard centraProd={centraProd} data={product} placement="Compare Page" position={index + 1} />
                </div>
              )
            })}
          </div>
        </div>

        {pageData && pageData[0].scrollGrids && pageData[0].scrollGrids.map((scrollGrid, index) => {
          return (
            <Sticky key={index} cname={scrollGrid && scrollGrid.textToRight ? stickyStyles.cdStickyGridReverse : ''} >
              <StickyText>
                <div dangerouslySetInnerHTML={{ __html: scrollGrid.text }}></div>
              </StickyText>
              <div className={stickyStyles.cdStickyGridRight}>
                {scrollGrid.images.map((stickyImage, index) => {
                  return (
                    <div key={index.toString()} className={`${stickyStyles.cdStickyGridImg}`}>
                      <Img alt="" fluid={stickyImage.fluid} />
                    </div>
                  )
                })}
              </div>
            </Sticky>
          )
        })}

        {pageData && pageData[0].backgroundCards &&
          <div className={compareStyles.cdCollabBackgroundCards}>
            <BackgroundCards country={props.pageContext.country} data={pageData[0].backgroundCards} />
          </div>
        }
      </div>

    </Layout>
  )
}


export default Collab;