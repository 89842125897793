import { useState, useEffect } from 'react'

const useIntersection = (element, rootMargin) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (element) {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    setIsVisible(entry.isIntersecting);
                    if ( entry.isIntersecting ) observer.unobserve(element);
                }, { rootMargin }
            );
        
            observer.observe(element);
    
            return () => observer.unobserve(element);
        }

    }, [element]);

    return isVisible;
};

export default useIntersection;