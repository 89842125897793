// extracted by mini-css-extract-plugin
export var cdProductCard = "product-card-module--cd-product-card--5f848";
export var cdProductCardBanner = "product-card-module--cd-product-card-banner--d292c";
export var cdProductCardButton = "product-card-module--cd-product-card-button--8a791";
export var cdProductCardButtons = "product-card-module--cd-product-card-buttons--ebab5";
export var cdProductCardButtonsWrapper = "product-card-module--cd-product-card-buttons-wrapper--b8b40";
export var cdProductCardContent = "product-card-module--cd-product-card-content--3f7a6";
export var cdProductCardContentLeft = "product-card-module--cd-product-card-content-left--3282a";
export var cdProductCardContentRight = "product-card-module--cd-product-card-content-right--a1000";
export var cdProductCardImg = "product-card-module--cd-product-card-img--668fc";
export var cdProductCardQuickAtc = "product-card-module--cd-product-card-quick-atc--34115";
export var cdProductCardTitle = "product-card-module--cd-product-card-title--12e94";
export var cdProductCardType = "product-card-module--cd-product-card-type--800e3";
export var cdProductOriginalPrice = "product-card-module--cd-product-original-price--a3830";
export var cdProductPrice = "product-card-module--cd-product-price--08937";
export var cdProductPriceDiscount = "product-card-module--cd-product-price-discount--83d6e";
export var cdSaleBadge = "product-card-module--cd-sale-badge--b1e56";
export var cdSaleBadges = "product-card-module--cd-sale-badges--5b51c";
export var cdWhiteButton = "product-card-module--cd-white-button--accf3";